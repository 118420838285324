<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('approval_page') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.form_description }}</td>
                                    </tr>
                                    <tr>
                                        <th width="30%" class="bg-secondary">{{ tt('type') }}</th>
                                        <td width="30%">{{ mcrInformation.type }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                   <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="tracking">{{ tt('tracking') }}</base-button>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem.data" style="height: 400px;"> <!--:data="table.item"-->
                        <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                 {{ row.material_number }}
                            </template>
                        </el-table-column>

                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.item_name }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status')" :prop="tt('status')" min-width="90px" sortable>
                            <template v-slot="{row}">
                                {{ row.status }}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('status_process')" :prop="tt('status_process')" min-width="90px" sortable>
                            <template v-slot="{row}">
                                {{ row.status_process }}
                            </template>
                        </el-table-column>
                        
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <!--el-dropdown trigger="click" class="dropdown" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <router-link :to="row.process_url+'/'+row.key"><el-dropdown-item><i class="ni ni-glasses-2"></i>Detail</el-dropdown-item></router-link>
                                        <router-link :to="row.process_url+'/'+row.key"><el-dropdown-item><i class="ni ni-scissors"></i>Edit</el-dropdown-item></router-link>
                                        <el-dropdown-item><i class="ni ni-fat-remove"></i>{{ tt('reject') }}</el-dropdown-item>
                                        <el-dropdown-item><i class="ni ni-ruler-pencil"></i>{{ tt('revise') }}</el-dropdown-item>
                                        <el-dropdown-item><i class="ni ni-user-run"></i>{{ tt('process') }}</el-dropdown-item>
                                        <router-link :to="row.process_url+'/'+row.key"><el-dropdown-item><i class="ni ni-archive-2"></i>{{ tt('note') }}</el-dropdown-item></router-link>
                                        <router-link :to="row.process_url+'/'+row.key"><el-dropdown-item><i class="ni ni-ruler-planet"></i>P/P ?</el-dropdown-item></router-link>
                                    </el-dropdown-menu>
                                </eldropdown-->

                                 <el-dropdown trigger="click" class="dropdown" @command="handleAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item><i class="ni ni-glasses-2"></i>Detail</el-dropdown-item>
                                            <el-dropdown-item><i class="ni ni-scissors"></i>Edit</el-dropdown-item>
                                            <el-dropdown-item :command="{pcsTyp:1, appType:'revise-item', mcrItemData:{kode: row.mcr_item_code, nama: row.item_name}}"><i class="ni ni-ruler-pencil"></i>Revise</el-dropdown-item>
                                            <el-dropdown-item :command="{pcsTyp:2, appType:'reject-item', mcrItemData:{kode: row.mcr_item_code, nama: row.item_name}}"><i class="ni ni-fat-remove"></i>Reject</el-dropdown-item>
                                            <el-dropdown-item :command="{pcsTyp:5, appType:'process', mcrItemData:{kode: row.mcr_item_code, nama: row.item_name}}"><i class="ni ni-user-run"></i>Process</el-dropdown-item>
                                            <el-dropdown-item><i class="ni ni-archive-2"></i>Note</el-dropdown-item>
                                        </el-dropdown-menu>
                                </el-dropdown>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <base-button size="sm" type="warning" @click="appMcr(11)" :disabled="isbRevise"><i class="ni ni-ruler-pencil"></i>{{ tt('revise') }}</base-button>
                    <base-button size="sm" type="danger" @click="appMcr(12)" :disabled="isbReject"><i class="ni ni-fat-remove"></i>{{ tt('reject') }}</base-button>
                    <base-button size="sm" type="success" @click="appMcr(13)" :disabled="isbApprove"><i class="ni ni-check-bold"></i>{{ tt('approve') }}</base-button>
                    <base-button size="sm" type="primary" @click="appMcr(14)" :disabled="isbResend"><i class="ni ni-send"></i>{{ tt('resend') }}</base-button>
                </div>
            </div>
        </div>
        <modal :show.sync="formTracking.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
            </template>
            <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking">
                <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.date }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.status }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.approver }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('jabatan')" :prop="tt('jabatan')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.jabatan }}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        {{ row.message }}
                    </template>
                </el-table-column>
            </el-table>
        </modal>
        <modal :show.sync="formNoteItems.show">
            <template slot="header">
                <h5 class="modal-title">{{ dlgTitle }}</h5>
            </template>
            <!--label class="form-control-label">Item Name: <span class="text-danger">{{ mcr_nama }}</span></label><br /-->
            <label class="form-control-label">Note<span class="text-danger">*</span></label>
            <el-input
              type="textarea"
              :rows="4"
              v-model="prosesNote.text"
              rules="required">
            </el-input>
            <template slot="footer">
                <base-button type="secondary" @click="formNoteItems.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary" v-on:click="prcApproveType()" :disabled="btnSave.onLoading"> <!--v-on:click="appMcrForm()"-->
                <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt('please_wait') }}</span>
                    <span v-else-if="prcType === 1">
                        <span>Revise</span>
                    </span>
                    <span v-else-if="prcType === 2">
                        <span>Reject</span>
                    </span>
                    <span v-else-if="prcType === 3">
                        <span>Approve</span>
                    </span>
                    <span v-else-if="prcType === 4">
                        <span>Resend</span>
                    </span>
                    <span v-else-if="prcType === 5">
                        <span>Proses</span>
                    </span>
                    <span v-else-if="prcType === 11">
                        <span>Revise MCR</span>
                    </span>
                    <span v-else-if="prcType === 12">
                        <span>Reject MCR</span>
                    </span>
                    <span v-else-if="prcType === 13">
                        <span>Approve MCR</span>
                    </span>
                    <span v-else-if="prcType === 14">
                        <span>Resend MCR</span>
                    </span>
                </base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';
    import formType from '@/services/setting/form.service';
    var moment = require('moment');
    export default {        
        data() {
            return {                
                moment:moment,
                dlgTitle: '',
                prcType : 0,
                clickFrom:'',
                btnStat: {
                    bRevise: true,
                    bReject: true,
                    bApprove: true,
                    bResend: true,
                },
                btnSave: {
                    onLoading: false
                },  
                formTracking: {
                    show: false
                },      
                formNoteItems: {
                    show: false
                },
                prosesNote: {
                    text:''
                }, 
                table: {
                    data: []
                },        
                equipmentCode: {},
                mcrInformation: {},
                draftList: {},
                masterForm: {
                    id: '',
                    form_code: '',
                    form_description: '',
                    form_type: '',
                    form_app: '',
                },
                mcr: {
                    data: []
                },
                mcrItem: {
                    data: []
                },
                appTracking: {
                    data: []
                },
                mcr_code: window.location.hash.split('/')[3],
                mcr_nama: '',
                token: window.location.hash.split('/')[4],
                mcr_kode:'',
                mcr_item_kode:'',
                formapp: '',
                key: ''
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            isbRevise: function(){
                return !this.btnStat.bRevise;
            },
            isbReject: function(){
                return !this.btnStat.bReject;
            },
            isbApprove: function(){
                return !this.btnStat.bApprove;
            },
            isbResend: function(){
                return !this.btnStat.bResend;
            },

        },
        mounted() {
            this.getMcrInfo()
        },
        methods: {
            getMcrInfo() {
                let context = this;

                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    //context.mcr.data = response.data.data.mcr;
                    context.mcrItem.data = response.data.data.mcr_item;
                    //context.mcrItem.page  = response.data.data.data;
                    context.mcrInformation = response.data.data.mcr[0];   
                    context.getformType(context.mcrInformation.type);
                })
                .call()
            },
            getformType(frmType){
                let context = this;  

                Api(context, formType.get({search: frmType})).onSuccess(function(response) {    
                    context.masterForm = response.data.data.data;
                    context.formapp = context.masterForm.data[0].form_app;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.masterForm = [];
                    }
                })
                .call()
            },
            handleAction(command) {
                //this.mcr_code = command.mcrItemData.kode;
                let context = this;

                context.mcr_nama            = command.mcrItemData.nama;
                context.clickFrom           = command.appType;
                context.approvalType        = command.appType;
                context.mcr_kode            = command.mcrItemData.kode;
                context.key                 = command.mcrItemData.kode;

                context.appMcr (command.pcsTyp);
            },
            tracking() {
                this.formTracking.title = "Add Equipment Code";
                this.formTracking.show = true;
            },
            appMcr(pi_pcsTyp){
                let context = this;
                context.prcType = pi_pcsTyp;
                if (pi_pcsTyp!=5){
                    context.formNoteItems.show = true;
                }
                switch (pi_pcsTyp) {
                    case 1: context.dlgTitle = 'Revise Item';break;
                    case 2: context.dlgTitle = 'Reject Item';break;
                    case 3: context.dlgTitle = 'Approve Item';break;
                    case 4: context.dlgTitle = 'Resend Item';break;
                    case 5: context.processCatalog(); break;
                    case 11: context.dlgTitle = 'Revise Form MCR';
                            context.key  = context.mcr_code;
                            break;
                    case 12: context.dlgTitle = 'Reject Form MCR';
                            context.key  = context.mcr_code;
                            break;
                    case 13: context.dlgTitle = 'Approve Form MCR';
                            context.key  = context.mcr_code;
                            break;
                    case 14: context.dlgTitle = 'Resend Form MCR';
                            context.key  = context.mcr_code;
                            break;
                }
            },
            prcApproveType(){
                let context = this;
               
                switch (this.prcType) {
                    case 1: context.processApprove('revise-item'); break; //revise
                    case 2: context.processApprove('reject-item'); break;
                    case 3: break;
                    case 4: context.processApprove('reapprove'); break;
                    case 11: context.processApprove('revise');break;
                    case 12: context.processApprove('reject');break;
                    case 13: context.processApprove('approve');break;
                    case 14: context.processApprove('reapprove');break;
                    //case 5: context.processCatalog(); break;
                }
               
            },
            processApprove(appType){
                 let api = null;
                 let context = this;
                
                context.formNoteItems.show = true;
                context.btnSave.onLoading = true;

                //api = Api(context, mcrApproval.appMcrForm(appType, context.mcr_code, {note : context.prsNote.text}));

                api = Api(context, mcrApproval.appMcrForm(appType, context.key, {note : context.prosesNote.text}));
                api.onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formNoteItems.show = false;
                    context.btnStat.bRevise = true;
                    //processCatalog();
                    //this.$router.push('/dashboard/my-task-show/MCRAPPROVAL'); 
                }).onFinish(function() {
                    context.formNoteItems.show = false;
                   
                    if (context.clickFrom == '') {
                        context.$router.push({path: '/dashboard/my-task-show/MCRAPPROVAL'})
                    }
                    
                    context.btnSave.onLoading = false;
                    context.getMcrInfo();
                    context.clickFrom = ''; 
                })
                .call();
                 context.prosesNote.text = '';
            },
            getTracking(){
                let context = this;
                Api(context, draftList.appTracking(context.mcr_code)).onSuccess(function(response) {                 
                    context.appTracking.data = response.data.data.data;               
                })
                .call()
            },
            processCatalog(){
                let context = this;
                
                this.$router.push('/material/aproval-page-'+ context.formapp +'/'+ context.mcr_code + '/' + context.token +'/'+ context.mcr_kode) //B2 akan dijadikan tipe page input dari table Form 
            },
        }   
    };
</script>
<style></style>
